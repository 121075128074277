$no-dark: #0F0F0F;
$no-light: #D1D1D1;
$no-extra-light: #fff;
$main-margin-left: 10vw;
$main-margin-right: 10vw;

$no-ex-small: 320px;
$no-small: 480px;
$no-mid: 600px;
$no-tablet: 801px;
$no-large: 1025px;
$no-ex-large: 1281px;

$no-container: 0 10% 0 10%;

$border-width: 0.3rem;

